import server from "./axios.config.js";

export const category = function(value) {
  return server({
    url: `http://110.87.103.59:18086/commonservice/dict/getList?typeCode=${value}`,
    method: "GET"
  })
}
export const categorys = function(parentId) {
  return server({
    url: `http://110.87.103.59:18086/commonservice/dict/getList?parentId=${parentId}`,
    method: "GET"
  })
}

// 新增养殖
export const postAnimalSave = function(options) {
  return server({
    url: "http://110.87.103.59:18086/farmfriend/animal/save",
    method: "POST",
    data: options
  })
}

// 查询养殖的牲畜
export const GetSelectAnimalVariety = function(penId) {
  return server({
    url: `http://110.87.103.59:18086/farmfriend/animal/selectList?pen_id=${penId}`,
    method: "GET"
  })
}

// 查询牲畜种类
export const getAnimalSmalltype = function(animalType) {
  return server({
    url: `http://110.87.103.59:18086/farmfriend/animalSmalltype/selectList?animalType=${animalType}`
  })
}

// 查询牲畜品种
export const getAnimalSmalltypeId = function(animalSmalltypeId) {
  return server({
    url: `http://110.87.103.59:18086/farmfriend/animalVariety/selectList?animalSmalltypeId=${animalSmalltypeId}`
  })
}