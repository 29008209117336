<template>
  <div class="my-field">
    <van-tabs :before-change="onBeforeChange" v-model="active">
      <p style="text-align: center">
        {{ StatisticsData.landCount }}个地块 种植
        {{ StatisticsData.landArea }} 亩，{{ StatisticsData.penCount }}个圈舍
        ，养殖 {{ StatisticsData.animalSum }} 只
      </p>
      <van-tab title="全部"> </van-tab>
      <van-tab title="地块">
        <div class="field-list">
          <van-collapse accordion v-model="activeNames">
            <van-collapse-item
              style="
                border-top: 1px solid #ddd;
                padding-bottom: 10px;
                margin-top: 10px;"
              v-for="(item, index) in fieldList"
              :name="index"
              :key="index"
            >
              <template #title>
                <div
                  @click.stop="collapseToggle(index, item.id)"
                  class="my-collapse-item"
                  style="height:12vh"
                >
                  <div class="wrap">
                    <div class="field-img">
                      <img v-if="item.imageUrl" :src="item.imageUrl" />
                      <div
                        v-else
                        style="width: 100%; height: 100%; background: #ccc"
                      ></div>
                    </div>
                    <div class="field-info">
                      <div class="top">
                        <p>
                          <strong @click.stop="toFieldDetail(item.id)">{{
                            item.name
                          }}</strong
                          ><span
                            >{{ item.province_name }}-{{ item.city_name }}-{{
                              item.county_name
                            }}</span
                          >
                        </p>
                        <span class="test" v-show="item.detect_num >= 1"><img src="../../assets/image/Field/组 1.png" alt=""></span>
                      </div>
                      <div class="bottom">
                        <div>{{ item.area }}亩</div>
                        <div>{{ item.crop_num }}种作物</div>
                        <div
                          class="btn-detail"
                          @click.stop="toAddCrop(item.id)"
                        >
                          作物添加
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template #right-icon>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    text-align:center
                  "
                  @click.stop="collapseToggle(index, item.id)"
                >
                  <img
                    style="margin-top: 20px"
                    src="../../assets/image/Field/footprint.png"
                    alt=""
                  />
                  <i class="van-icon van-icon-arrow van-cell__right-icon"
                    ><!----></i
                  >
                </div>
              </template>

              <div class="my-collapse-content">
                <div v-if="loading" class="requesting">数据加载中...</div>
                <div else class="hadRequest">
                  <div class="wrap" v-if="OperList && OperList.length > 0">
                    <div class="collapse-content-item">
                      <div class="cropList">
                        <div
                          class="crop"
                          v-for="(item, index) in OperList"
                          @click.stop="toCropDetail(item.id)"
                          :key="index"
                        >
                          <div class="crop-left">
                            <img v-if="item.cropImage" :src="item.cropImage" />
                            <div v-else></div>
                          </div>
                          <div class="crop-right">
                            <div class="info">
                              <div class="name">{{ item.crop_name }}</div>
                              <div class="typeName">
                                {{ item.crop_type_name }}
                              </div>
                              <div class="area">{{ item.area }}亩</div>
                            </div>
                            <div class="date-info">
                              <div>
                                <img
                                  src="../../assets/image/Field/组 3.png"
                                  alt=""
                                />{{ item.plant_time | dateFormat
                                }}<span
                                  >种后{{ item.after_plant_days }}天</span
                                >
                              </div>
                              <div style="margin-left:10px">
                                <img
                                  src="../../assets/image/Field/组 2.png"
                                  alt=""
                                />{{ item.expect_harvest_time | dateFormat
                                }}<span>{{ item.to_harvest_days }}</span
                                >天后
                              </div>
                            </div>
                            <div v-if="item.currentCropLife" class="day-info">
                              <img
                                src="../../assets/image/Field/组 4.png"
                                alt=""
                              />
                              <span
                                v-for="items in item.currentCropLife
                                  .phenologyOperList.slice(0,3)"
                                :key="items.id"
                                >{{ items.name }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    style="padding: 20px 0; font-size: 20px; text-align: center"
                  >
                    此地块暂时没有作物，请先添加作物
                  </div>
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </van-tab>
      <van-tab title="大棚"> 大棚 </van-tab>
      <van-tab title="圈舍">
        <van-collapse accordion v-model="activeNamess">
          <van-collapse-item
            :name="index"
            class="list-li"
            v-for="(item, index) in EnclosureList"
            :key="index"
          >
            <template #title>
              <div class="list" @click.stop="getcollapseToggle(index, item.id)">
                <div class="list-li-item">
                  <img :src="item.imageUrl" alt="" />
                  <div class="content">
                    <div class="content-top">
                      <div class="name">{{ item.name }}</div>
                    </div>
                    <div class="carbon">
                      <div class="carbon-name">
                        {{ item.province_name }}-{{ item.city_name }}-{{
                          item.county_name
                        }}
                      </div>
                    </div>
                    <div class="carbon-button">
                      <div
                        class="AddEnclosure"
                        @click="toAddEnclosure(item.id)"
                      >
                        添加养殖
                      </div>
                      <div
                        class="carbon-button-detail"
                        @click="toEnclosureDetails(item.id)"
                      >
                        养殖详情
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #right-icon>
              <div @click.stop="getcollapseToggle(index, item.id)">
                <i class="van-icon van-icon-arrow van-cell__right-icon"
                  ><!----></i
                >
              </div>
            </template>
            <div class="my-collapse-content">
              <div v-if="loading" class="requesting">数据加载中...</div>
              <div else class="hadRequest">
                <div
                  class="wrap"
                  v-if="item.livestockList && item.livestockList.length > 0"
                >
                  <div class="collapse-content-item">
                    <div class="cropList">
                      <div
                        class="crops"
                        v-for="(item, index) in item.livestockList"
                        :key="index"
                        @click="livestock(item.id)"
                      >
                        <div class="crop-left">
                          <img v-if="item.imageUrl" :src="item.imageUrl" />
                          <div v-else></div>
                        </div>
                        <div class="crop-right">
                          <div class="top">
                            <div>入栏时间:{{ item.enter_date }}</div>
                            <div>日龄:{{ item.dayCount }}</div>
                            <div>周龄:{{ item.weekCount }}</div>
                          </div>
                          <div class="center">
                            <div>存栏数量:{{ item.exist_num }}</div>
                            <div>
                              <button
                                style="padding: 0 2.13333; background: #d0e17d"
                              >
                                碳{{ 0 }}
                              </button>
                            </div>
                          </div>
                          <div class="bottom">
                            <div>预计出栏{{ item.planOutDate }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  style="padding: 20px 0; font-size: 20px; text-align: center"
                >
                  此圈舍暂无畜禽，请先添加畜禽
                </div>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
      </van-tab>
      <van-tab>
        <template #title>
          <van-icon @click="que" name="add" size="20px"
        /></template>
      </van-tab>
    </van-tabs>
    <!--签合同dialog && 调研dialog -->
    <div class="my-van-dialog">
      <van-dialog
        v-model="contractDialogShow"
        title="田地合同"
        show-cancel-button
        :beforeClose="contractDialogClose"
      >
        <div class="dialog-contract-wrap">
          <div class="my-btn" @click.stop="contractChange(1)">
            <my-btn-tag :active="tempContract == 1">已签合同</my-btn-tag>
          </div>
          <div class="my-btn" @click.stop="contractChange(0)">
            <my-btn-tag :active="tempContract == 0 || tempContract == undefined"
              >未签合同</my-btn-tag
            >
          </div>
        </div>
      </van-dialog>

      <van-dialog
        v-model="researchDialogShow"
        title="田地调研"
        show-cancel-button
        :beforeClose="researchDialogClose"
      >
        <div class="dialog-research-wrap">
          <div class="my-btn" @click.stop="researchChange(1)">
            <my-btn-tag :active="tempResearch == 1">已调研</my-btn-tag>
          </div>
          <div class="my-btn" @click.stop="researchChange(0)">
            <my-btn-tag :active="tempResearch == 0 || tempResearch == undefined"
              >未调研</my-btn-tag
            >
          </div>
        </div>
      </van-dialog>
    </div>
    <!-- 添加 -->
    <van-popup
      v-model="showPopup"
      position="right"
      :style="{ width: '30%', height: '100%' }"
    >
      <ul>
        <li @click="toAddLand">添地块</li>
        <hr />
        <li @click="toEnclosure">添圈舍</li>
        <hr />
        <li>添大棚</li>
      </ul>
    </van-popup>
  </div>
</template>

<script>
import {
  Collapse,
  CollapseItem,
  Dialog,
  Notify,
  Toast,
  Tab,
  Tabs,
  Icon,
  Popup
} from "vant";
import MyBtnTag from "@/components/server/BtnTag.vue";
import {
  postFieldContract,
  postFieldResearch,
  getLandCropList,
  landStatistic,
  landListNew
} from "@/service/serverPage/myField.js";
import { getEnclosureList } from "../../service/Enclosure/Enclosure";
import { GetSelectAnimalVariety } from "../../service/AddBreeding";
export default {
  name: "MyField",
  components: {
    "van-dialog": Dialog.Component,
    "van-collapse": Collapse,
    "van-collapse-item": CollapseItem,
    "my-btn-tag": MyBtnTag,
    "van-tab": Tab,
    "van-tabs": Tabs,
    "van-icon": Icon,
    "van-popup": Popup
  },
  props: {
    fieldList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeNamess: -1,
      activeNames: -1,
      fieldDefaultImg: require("../../assets/image/server/default-field.png"),
      contractDialogShow: false, // 田地合同 dialog 显隐
      researchDialogShow: false, // 田地调研 dialog 显隐
      currentFieldIndex: -1, // 临时田地的index
      tempContract: -1, // 临时合同状态记录 0未签合同 1已签合同
      tempResearch: -1, // 临时调研状态记录 0未调研 1已调研
      loading: false,
      EnclosureList: [],
      StatisticsData: {},
      active: 1,
      checked: true,
      showPopup: false,
      data: {
        expect_harvest_time: "",
        plant_time: ""
      },
      OperList: []
    };
  },
  created() {
    landStatistic().then((res) => {
      this.StatisticsData = res.data;
    });
    this.init();
  },
  computed: {},
  filters: {
    dateFormat(date, msg) {
      const dt = new Date(date);
      const m = (dt.getMonth() + 1 + "").padStart(2);
      const d = (dt.getDate() + "").padStart(2);
      return `${m}月${d}日`;
    }
  },
  methods: {
    onBeforeChange(name) {
      return !(name === 4);
    },
    // 打开添加弹窗
    que() {
      this.showPopup = true;
    },
    onSelect() {},
    init() {
      getEnclosureList().then((res) => {
        this.EnclosureList = res.data;
      });
    },
    toFieldMap() {
      this.$router.push({ path: "/fieldMap" });
    },
    toAddCrop(fieldId) {
      this.$router.push({ path: `/addCrop/${fieldId}` });
    },
    toFieldDetail(fieldId) {
      this.$router.push({ path: `/server/fieldDetail/${fieldId}` });
    },
    toCropDetail(id) {
      this.$router.push({ path: `/server/cropDetail/${id}` });
    },
    // 去牲畜详情
    toEnclosureDetails(id) {
      this.$router.push({ path: `/server/EnclosureDetails/${id}` });
    },
    // 去添加地块
    toAddLand() {
      this.$router.push({ path: "/addfarmland" });
    },
    // 去添加圈舍
    toEnclosure() {
      this.$router.push({ path: "/addEnclosure" });
    },
    toAddEnclosure(id) {
      this.$router.push({ path: `/server/AddBreeding/${id}` });
    },
    // 种植方式
    plantType(type) {
      let tempVal = "";
      if (type === "zb") {
        tempVal = "播种";
      } else if (type === "ymyz") {
        tempVal = "移栽";
      } else if (type === "dnszp") {
        tempVal = "萌芽";
      }
      return tempVal;
    },

    /*******
     * 合同Tag点击事件
     * index：当前操作的田地编号
     * state：当前contract的状态
     ********/
    fieldContract(index, state) {
      // 记录临时状态（弹出框可用临时状态初始化数据）
      this.tempContract = state;
      // 记录当前操作的第几条数据
      this.currentFieldIndex = index;
      // dialog弹出
      this.contractDialogShow = true;
    },

    // 田地合同状态改变
    contractChange(state) {
      this.tempContract = state;
    },

    // 合同dialog关闭操作
    contractDialogClose(action, done) {
      if (action === "confirm") {
        // 合同状态
        const tempContract = this.tempContract;
        // 田地id
        const landId = this.fieldList[this.currentFieldIndex].id;
        // 请求接口
        postFieldContract(tempContract, landId)
          .then((res) => {
            if (res.code === 200) {
              Notify({ type: "success", message: "修改成功" });
              // 关闭dialog
              done();

              // 修改当前田块合同状态 （暂时不请求接口更新页面）
              const index = this.currentFieldIndex;
              this.fieldList[index].contract = this.tempContract;

              // 初始化临时变量
              // this.currentFieldIndex = -1;
              // this.tempContract = -1;
            } else {
              Notify({ type: "danger", message: "修改失败" });
              done();
              // 初始化临时变量
              // this.currentFieldIndex = -1;
              // this.tempContract = -1;
            }
          })
          .catch(() => {
            Notify({ type: "danger", message: "修改失败" });
            done();
            // 初始化临时变量
            this.currentFieldIndex = -1;
            this.tempContract = -1;
          });
      } else if (action === "cancel") {
        done();
        // 初始化临时变量
        // this.currentFieldIndex = -1;
        // this.tempContract = -1;
      }
    },

    /*******
     * 调研Tag点击事件
     * index：当前操作的田地编号
     * state：当前contract的状态
     ********/
    fieldResearch(index, state) {
      this.researchDialogShow = true;
      this.currentFieldIndex = index;
      this.tempResearch = state;
    },

    researchChange(state) {
      this.tempResearch = state;
    },

    researchDialogClose(action, done) {
      if (action === "confirm") {
        // 调研状态
        const tempResearch = this.tempResearch;
        // 田地id
        const landId = this.fieldList[this.currentFieldIndex].id;
        postFieldResearch(tempResearch, landId)
          .then((res) => {
            if (res.code === 200) {
              Notify({ type: "success", message: "修改成功" });
              // 关闭dialog
              done();
              const index = this.currentFieldIndex;
              this.fieldList[index].survey = this.tempResearch;
              // 初始化临时变量
            } else {
              Notify({ type: "danger", message: "修改失败" });
              done();
              // 初始化临时变量
              // this.currentFieldIndex = -1;
              // this.tempContract = -1;
            }
          })
          .catch(() => {
            Notify({ type: "danger", message: "修改失败" });
            done();
            // 初始化临时变量
            // this.currentFieldIndex = -1;
            // this.tempContract = -1;
          });
      } else if (action === "cancel") {
        done();
        // this.currentFieldIndex = -1;
        // this.tempResearch = -1;
      }
    },

    routerFunc(index) {
      this.$router.push({ path: `/server/soilList/${index}` });
    },

    collapseToggle(index, fieldId) {
      if (!this.fieldList[index].cropList) {
        const myToast = Toast.loading({
          message: "加载中...",
          forbidClick: true,
          duration: 0
        });
        getLandCropList(fieldId).then((res) => {
          if (res.code === 200) {
            this.$set(this.fieldList[index], "cropList", res.data);
            this.$nextTick(() => {
              this.activeNames = index;
              myToast.clear();
            });
          }
        });
        landListNew(fieldId).then((res) => {
          this.OperList = res.data;
        });
      } else {
        if (this.activeNames === index) {
          this.activeNames = -1;
        } else {
          this.activeNames = index;
        }
      }
    },
    getcollapseToggle(index, fieldId) {
      if (!this.EnclosureList[index].livestockList) {
        const myToast = Toast.loading({
          message: "加载中...",
          forbidClick: true,
          duration: 0
        });
        GetSelectAnimalVariety(fieldId).then((res) => {
          if (res.code === 200) {
            this.livestockList = res.data;
            this.$set(this.EnclosureList[index], "livestockList", res.data);
            this.$nextTick(() => {
              this.activeNamess = index;
              myToast.clear();
            });
          }
        });
      } else {
        if (this.activeNamess === index) {
          this.activeNamess = -1;
        } else {
          this.activeNamess = index;
        }
      }
    },
    livestock(index) {
      this.$router.push({ path: `/server/livestockDetails/${index}` });
    }
  }
};
</script>

<style lang="scss" scoped>
.crops {
  border: 1px solid #ddd;
  display: flex;
  width: 100%;
  margin-top: 0.5vh;
  .crop-right {
    margin-left: 5px;
    width: 100%;
    .top {
      display: flex;
      justify-content: space-between;
    }
    .center {
      display: flex;
      justify-content: space-between;
    }
  }

  .crop-left {
    flex: 0 0 80px;
    img {
      width: 100%;
      max-height: 76px;
    }
    div {
      background: lightblue;
    }
  }
}
.wrap {
  .field-info {
    .top {
      display: flex;
      align-items: center;
      .test {
        line-height: 15px;
        margin-left: 10px;
        img{
          width: 20px;
        }
      }
      p {
        width: 200px;
        overflow: hidden; //超出文本隐藏
        white-space: nowrap; //不换行，只显示一行
        text-overflow: ellipsis; //超出部分省略号显示
        span {
          margin-left: 20px;
        }
        strong {
          font-size: 17px;
        }
      }
    }
    .bottom {
      display: flex;
      width: 53.33333vw;
      div {
        text-align: left;
        width: 33%;
      }
    }
  }
}
.list-li {
  border-top: 1px solid rgb(221, 221, 221);
  padding-top: 10px;
  padding-right: 2vw;
}
.my-field {
  // 养殖部分
  .list {
    width: 100%;
    .list-li-item {
      padding-bottom: 1vh;
      display: flex;
      margin: 0.9vh;
      img {
        width: 30%;
        height: 29.33333vw;
      }
      .content {
        margin-left: 1.3vw;
        width: 63%;
        flex-direction: column;
        .carbon-button {
          display: flex;
          justify-content: space-between;
          .carbon-button-detail {
            margin-top: 1.33333vw;
          }
        }
        .content-top {
          display: flex;
          .name {
            padding-right: 5.33333vw;
          }
          .linkman {
            flex: 1 0 auto;
          }
        }
        .carbon {
          display: flex;
          margin: 3vw 0;
          align-items: center;
          .carbon-button {
            margin-left: 6.4vw;
            background: #d0e17d;
            padding: 0 2.13333vw;
          }
        }
        .content-bottom {
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: space-between;
        }
      }
      .AddEnclosure {
        display: inline-block;
        // background: #001f90;
        // color: #fff;
        margin-top: 5px;
      }
    }
  }
  // 种植部分
  .van-tabs__wrap {
    position: relative;
  }
  .map-btn {
    background: #001f90;
    padding: 4px 10px;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
  }
  .field-list {
    margin: 0 10px;
  }
}

.my-collapse-item {
  .wrap {
    display: flex;
    margin-top: 10px;
    .field-img {
      flex: 0 0 88px;
      height: 23.33333vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .field-info {
      flex: 0 1 auto;
      flex-wrap: wrap;
      padding-left: 12px;
      .field-name {
        font-weight: 600;
        padding-right: 20px;
      }
      .top {
        .name-and-owner {
          display: flex;
          .field-owner {
            flex: 1 0 auto;
          }
        }
        .position-and-detail {
          display: flex;
          margin: 10px 0;
          align-items: center;
          .field-carbon {
            margin-left: 24px;
            background: #d0e17d;
            padding: 0 8px;
          }
        }
      }
      .middle {
        display: flex;
        flex-wrap: wrap;
        margin: 10px 0;
        .tag-wrap:not(:last-child) {
          margin-right: 8px;
        }
        .take-soil {
          position: relative;
          .take-soil-num {
            position: absolute;
            top: -8px;
            right: -8px;
            width: 12px;
            height: 12px;
            padding: 2px;
            background: #ff5722;
            color: #fff;
            line-height: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
          }
        }
      }
      .bottom {
        display: flex;
        .btn-detail {
          color: #5352ed;
          & + .btn-detail {
            margin-left: 16px;
          }
        }
      }
    }
  }
}

.my-collapse-content {
  color: #333;
  .wrap {
    .collapse-content-item {
      display: flex;
      margin: 2px 0;
      .cropList {
        width: 100%;
        .crop {
          display: flex;
          border: 1px solid #ddd;
          align-items: center;
          border-radius: 4px;
          height: 80px;
          padding: 2px;
          & + .crop {
            margin: 5px 0;
          }
          .crop-left {
            flex: 0 0 80px;
            img {
              width: 100%;
              max-height: 76px;
            }
            div {
              background: lightblue;
            }
          }
          .crop-right {
            height: 100%;
            display: flex;
            flex: 1 0 auto;
            flex-direction: column;
            justify-content: space-around;
            padding-left: 10px;
            .info {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
            }
            .lifeAndCondition {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              .life {
                margin-right: 14px;
              }
              .carbon {
                background: #d0e17d;
                padding: 0 8px;
              }
            }
            .date-info {
              font-size: 2.2vw;
              display: flex;
              div {
                display: flex;
                align-items: center;
                line-height: 20px;
                div:nth-child(2) {
                  margin-left: 10px;
                }
                span {
                  margin-left: 5px;
                }
                img {
                  margin-right: 3px;
                  width: 16px;
                  height: 16px;
                }
              }
            }
            .day-info {
                width: 67vw;
                display: flex;
                align-items: center;
                overflow: hidden; //超出文本隐藏
                white-space: nowrap; //不换行，只显示一行
                text-overflow: ellipsis; //超出部分省略号显示
              img {
                width: 16px;
                height: 16px;
              }
              span {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.my-van-dialog {
  .dialog-contract-wrap,
  .dialog-research-wrap {
    display: flex;
    margin: 34px 0;
    justify-content: center;
    .my-btn {
      margin: 0 14px;
    }
  }
}
ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  li {
    font-size: 18px;
    margin-top: 10px;
  }
  hr {
    width: 100%;
  }
}
</style>

<style lang="scss">
/* vant 样式修改 */
.my-field .van-collapse-item__content {
  padding: 10px 0 0 0;
}

.my-field .van-collapse-item .van-cell {
  padding: 0;
}
</style>
