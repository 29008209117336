<template>
  <div class="server">
    <!-- 服务首屏加载  start -->
    <template v-if="Array.isArray(fieldList) && fieldList.length > 0">
      <div class="weather-and-field">
        <div class="crop">
          <!-- <my-cropList /> -->
        </div>
      </div>
      <div class="field-content">
        <my-field :fieldList="fieldList"/>
      </div>
    </template>
    <template v-else-if="Array.isArray(fieldList) && fieldList.length === 0">
      <div class="no-data">暂无地块信息，请添加地块</div>
    </template>
    <template v-else>
      <van-skeleton :row="18" />
    </template>
    <!-- 服务首屏加载  end -->
    <nav-menu></nav-menu>
  </div>
</template>

<script>
import { Skeleton } from "vant";
import Menu from "@/views/menu/menu";
// import CropList from "./cropList.vue";
import MyField from "./myField.vue"; // 地块列表
// import MyLivingWeather from "@/components/home/LivingWeather.vue";
import { getFieldList } from "@/service/serverPage/myField.js";

export default {
  name: "ServerIndex", // 服务-主页
  components: {
    "nav-menu": Menu,
    // "my-cropList": CropList,
    "my-field": MyField,
    "van-skeleton": Skeleton
  },
  data() {
    return {
      fieldList: null
    };
  },
  created() {
    getFieldList().then(res => {
      if (res.code === 200) {
        this.fieldList = res.data;
      } else if (res.code === 401) {
        this.fieldList = res.data;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.server {
  padding-bottom: 60px;
  .weather-and-field {
    color: #fff;
    .field {
      flex: auto;
      background: #5352ed;
    }
  }
  .field-content {
    margin-top: 10px;
    .field-item {
      margin: 10px 0;
      padding: 10px;
      background: lightgreen;
    }
  }
  .no-data {
    display: flex;
    justify-content: center;
    font-size: 20px;
    margin-top: 100px;
  }
}
</style>
